<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <prospect-list></prospect-list>
      </v-tab-item>

      <v-tab-item>
        <marketing-messages></marketing-messages>
      </v-tab-item>

      <v-tab-item>
        <marketing-settings></marketing-settings>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiAccountGroupOutline, mdiMessageBulleted, mdiTelevision } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import ProspectList from './ProspectList.vue'
import MarketingMessages from './MarketingMessages.vue'
import MarketingSettings from './MarketingSettings.vue'
import axios from 'axios'

export default {
  components: {
    ProspectList,
    MarketingMessages,
    MarketingSettings,
  },

  data() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Prospects', icon: mdiAccountGroupOutline },
      { title: 'Messages', icon: mdiMessageBulleted },
      { title: 'Send Ads', icon: mdiTelevision },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiAccountGroupOutline,
        mdiMessageBulleted,
        mdiTelevision,
      },
    }
  },
}
</script>
